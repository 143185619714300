angular.module('fingerink')
    .directive('avanzadaAttached', function () {
        return {
            restrict: 'AEC',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-attached.tpl.html',
            scope: {
                data: '=',
                fillable: '=',
                heightPx: '=',
                widthPx: '='
            },
            controllerAs: 'controller',
            controller: 'avanzadaAttachedCtrl'
        };
    })
    .controller('avanzadaAttachedCtrl', function ($rootScope, $scope, $element, $uibModal, $stateParams, signatureService, $translate, $timeout, swalService) {
        var that = this;

        that.add = function () {
            $timeout(function () {
                $element.find('input')[0].click();
                $scope.$apply();

            }, 0);
        };
        that.download = function () {
            signatureService.retrieveFirmaFiles($scope.data.id).then(function (response) {
                var downloadLink = angular.element('<a></a>');
                downloadLink.attr('href', response.data);
                downloadLink.attr('download', $scope.data.answer);
                downloadLink[0].click();
            });
        };
        $scope.$watch('controller.files', function () {
            if ($scope.fillable) {
                if (that.files && that.files[0]) {
                    swalService.requestSwal('Se va a subir el archivo', null, 'warning',()=> signatureService.uploadFirmaFile(that.files[0], $scope.data.id, $stateParams.token))
                        .then(function (response) {
                            swalService.basicSwal("Archivo subido", "El archivo se ha subido correctamente", "success");
                            $scope.data.answer = response.data;
                        }, function () {
                            swalService.basicSwal("Algo ha ido mal", "Ha habido un error intentando subir el archivo, por favor, inténtelo de nuevo más tarde", "error");
                            delete that.files[0];
                        });
                }
            }
        });

        $scope.$on('call' + $scope.data.id, function (event, data) {
            that.add();
        });


    });
